<template>
    <Layout>
        <!-- <PageHeader :title="title" :items="items" /> -->
        <div class="row">
            <div class="col-12">
                <ul class="nav nav-tabs pt-3" style="border: 0;">
                    <li class="active"><a href="#" class="btn btn-sm btn-head text-white">User Access Tracking Shipment</a></li>
                </ul>
                <div class="card new-card">
                    <div class="card-body">
                        <!-- <div class="row">

          </div> -->
                        <ShipmentAccess :show-action="true"></ShipmentAccess>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import appConfig from '@src/app.config'
    import Layout from '@layouts/main'
    // import PageHeader from '@components/page-header'
    import ShipmentAccess from '@components/TrackingShipment/ShipmentAccessTable.vue'

    // import { mapActions } from 'vuex'

    export default {
        page: {
            title: 'Shipment Access',
            meta: [{
                name: 'description',
                content: appConfig.description,
            }, ],
        },
        components: {
            ShipmentAccess,
            Layout,
            // PageHeader,
        },
        data() {
            return {
                title: 'Shipment Access',
                items: [{
                        text: 'Master',
                        href: '/',
                    },
                    {
                        text: 'Shipment Access',
                        href: '/shipment-access',
                    },
                    {
                        text: 'List',
                        active: true,
                    },
                ],
            }
        },
    }

</script>
