<template>
	<div>
		<!-- <h4 class="header-title">List Quotation</h4> -->
		<vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />
		<div class="row">
			<div class="col-lg-3">
				<b-button v-can="'SHIPMENT_ACCESS_CREATE'" class="btn btn-create btn-sm" style="background: #51C1AD; border: 1px; height: 35px;" @click="openModalAdd">
					<i class="mdi mdi-plus mr-1"></i>
					Add New 
				</b-button>
			</div>
			<div class="col-sm-12 col-md-12 col-lg-9">
				<div class="float-lg-right float-left">
					<div class="form-inline">
						<label class="mb-2 mr-sm-2">Search :</label>
						<input v-model="filterText" type="text" class="form-control form-control-sm mb-2" placeholder="Search . . ." @keyup="doFilter" />
						<!-- <button type="button" class="btn btn-primary mb-2 mr-sm-2" @click="doFilter">Go</button> -->
						<!-- <button type="button" class="btn btn-danger mb-2 mr-sm-2" @click="resetFilter">Reset</button> -->
					</div>
				</div>
			</div>
		</div>

		<!-- Table -->
		<div class="table-responsive">
			<vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true" :http-options="httpOptions" :show-sort-icons="true" :append-params="moreParams" pagination-path class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable" @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed" @vuetable:pagination-data="onPaginationData">
				<!-- <div slot="item" slot-scope="props" class="button-ri row">
					<a :href="`/ReceivableItem/detail/${props.rowData.id}`" class="mr-1"> {{
							props.rowData.item
					}}
						item

						<svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z" fill="#FFA800" />
						</svg>
					</a>
				</div> -->
				<div slot="actions" slot-scope="props" class="button-ri row" >
					<button v-can="'SHIPMENT_ACCESS_UPDATE'" class="btn btn-action mr-1" style="background: #51C1AD; height:28px" href="javascript:void(0)" title="View Detail" @click="openModalEdit(props.rowData.id)">
					<img src="@assets/images/icon-edit.svg" id="edit" class="edit" border="0">
					</button>
					<button v-can="'SHIPMENT_ACCESS_DELETE'" class="btn btn-action" style="background: #EA4C40; height:28px" href="javascript:void(0)" title="Delete" @click="showConfirmDelete(props.rowData.id, 'shipment_data')">
					<img src="@assets/images/icon-delete.svg" id="edit" class="edit" border="0">
					</button>
				</div>
				<div slot="total_access" slot-scope="props" class="button-po row">
					<button class="btn" style="font-size:12px" @click="openModalAccess(props.rowData.id)"> {{
						props.rowData.access_user.length
					}} users
						<svg class="" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8.5 1C8.5 0.723857 8.27614 0.5 8 0.5L3.5 0.5C3.22386 0.5 3 0.723858 3 1C3 1.27614 3.22386 1.5 3.5 1.5L7.5 1.5L7.5 5.5C7.5 5.77614 7.72386 6 8 6C8.27614 6 8.5 5.77614 8.5 5.5L8.5 1ZM1.35355 8.35355L8.35355 1.35355L7.64645 0.646447L0.646447 7.64645L1.35355 8.35355Z"
							fill="#FFA800" />
						</svg>
					</button>
					

				</div>
				<div slot="is_active" slot-scope="props" class="button-po row">
					<toggle-button class="mt-1" color="#51C1AD" :disabled="isExpired(props.rowData.expired_date)" :value="props.rowData.is_active" @change="changeActive(props.rowData.id)"/>
					<!-- <toggle-button :value="props.rowData.is_active" color="#82C7EB" :sync="true" :labels="true"/> -->

				</div>
			</vuetable>
		</div>
		<!-- end table -->

		<!-- pagination -->
		<div class="row">
			<div class="col-md-5">
				<div class="row no-gutters">
					<div class="col-lg-3">
						<div class="form-group float-lg-left">
							<select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
								<option v-for="opt in lengthMenu" :key="opt" :value="opt">
									{{ opt }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-8">
						<vuetable-pagination-info ref="paginationInfo" class="
                justify-content-center justify-content-lg-start
                text-center text-lg-left
              ">
						</vuetable-pagination-info>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-7">
				<vuetable-pagination ref="pagination" :css="css.pagination" class="
            pagination pagination-rounded
            justify-content-center justify-content-lg-end
          " @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
		<!-- end pagination -->

        <!-- modal add new -->
        <b-modal id="modal-add" ok-title="Submit" @show="resetModalAdd" @ok="saveAdd" @hidden="resetModalAdd" :ok-disabled="this.buttonSubmitDisable">
            <template v-slot:modal-title><span class="label-modal">Add Access User</span></template>
            <b-form ref="formAdd" @submit.stop.prevent="handleSubmit">
                <b-form-group
                    id="input-group-1"
                >
                <label label-for="input-1">Customer <span class="text-danger" aria-required="true"> * </span></label>
                <v-select v-model="dataAdd.customer"	
                :options="listCustomers" id="accessCustomer" 
                :reduce="(option) => option.id"
                label="name"
                placeholder="Choose customer"
                @input="changeCustomer"
				menu-props="auto"
				persistent-hint
				return-object
				single-line>
                >
                </v-select>
            </b-form-group>
            <div v-if="showAdd">
                <b-form-group id="input-group-2">
					<label label-for="input-2">Username <span class="text-danger" aria-required="true"> * </span></label>
                    <b-form-input
                    id="input-2"
                    v-model="dataAdd.username"
                    placeholder="Username"
                    required
                    ></b-form-input>
					<!-- <span class="text-danger" aria-required="true"> * </span> -->
                </b-form-group>

                <b-form-group id="input-group-3" label-for="input-3">
                    <label label-for="input-1">Email <span class="text-danger" aria-required="true"> * </span></label>
                    <b-form-input
                        id="input-3"
                        v-model="dataAdd.email"
                        placeholder="email"
                        required
                        >
                    </b-form-input>
                    <p class="desc-email-add mt-1">Access email can be different from the email that was previously registered</p>
                </b-form-group>

                <b-form-group id="input-group-4">
                    <label>Expired Date</label><span class="text-danger" aria-required="true"> * </span>
                    <date-picker v-model="dataAdd.date" :format="momentFormatDate" @input="getDate" type="date" lang="en" style="height: 31px;" :placeholder="'Select Date'">
					</date-picker>
                </b-form-group>
            </div>
            </b-form>
        </b-modal>

		<!-- modal edit -->
		<b-modal id="modal-edit" size="200">
			<template v-slot:modal-title><span class="label-modal">Total Access</span></template>
			<div class="row">
				<div class="col-md-8">
					<label for="">Expired Date : <span style="color:#EA4C40">{{ dateFormating(this.expiredData.expired_date) }}</span></label>
					<button @click="openModalEditDate" class="btn mb-1"><img src="@assets/images/icon-pencil.svg" alt="" srcset=""></button>
				</div>
				<div class="col-md-4">
					<button id="add-user" @click="openModalAddUser" class="btn mb-1"><img src="@assets/images/icon-add.svg" alt="" srcset=""></button>
					<label for="add-user">Add Users</label>
				</div>
			</div>
			<b-table ref="tableEdit" small :fields="fieldsUsers" :bordered="true" :items="usersTable" responsive="sm" thead-class="table-total-access">
				<template v-slot:cell(actions)="rows">
					<button class="btn btn-action mr-1" style="background: #51C1AD; height:28px" href="javascript:void(0)" title="View Detail" @click="openModalEditDetail(rows.item.id)">
						<img src="@assets/images/icon-edit.svg" id="edit" class="edit" border="0">
					</button>
					<button class="btn btn-action" style="background: #EA4C40; height:28px" href="javascript:void(0)" title="Delete" @click="showConfirmDelete(rows.item.id, 'shipment_data_user')">
						<img src="@assets/images/icon-delete.svg" id="edit" class="edit" border="0">
					</button>
      			</template>
			</b-table>
			<template v-slot:modal-footer>
				<div class="row" style="width: 100%">
					<div class="d-flex justify-content-start col-md-10">
						<b-button size="sm" style="color: #999797" variant="light" @click="modalEditClose">Cancel</b-button>
					</div>
					<div class="col-md-2" v-if="usersTable.length > 0">
						<b-button size="sm" style="background-color: #51C1AD; color: #FFFFFF" variant="light" @click="handleSubmitEditUsers">Save</b-button>
					</div>
				</div>
			</template>
		</b-modal>

		<!-- total access -->
		<b-modal id="modal-total-access" size="200">
			<template v-slot:modal-title><span class="label-modal">Total Access</span></template>
			<b-table ref="tableEdit" small :fields="fieldsAccess" :bordered="true" :items="usersAccessTable" responsive="sm" thead-class="table-total-access">
			</b-table>
			<template v-slot:modal-footer>
				<div class="row" style="width: 100%">
					<div class="d-flex justify-content-start col-md-10">
						<b-button size="sm" style="color: #999797" variant="light" @click="modalAccessClose">Cancel</b-button>
					</div>
				</div>
			</template>
		</b-modal>

		<!-- edit date -->
		<b-modal id="modal-edit-date" ok-title="Save" size="80" no-close-on-backdrop>
			<template v-slot:modal-title><span class="label-modal">Edit Expired Date</span></template>
			<b-form-group id="input-group-4">
				<label label-for="date-input-expired">Expired Date</label><span class="text-danger" aria-required="true"> * </span>
				<date-picker v-model="expiredData.expired_date" :format="momentFormatDateEdit" type="date" id="date-input-expired" lang="en"
					style="height: 31px;" :placeholder="'Select Date'">
				</date-picker>
			</b-form-group>
			<template v-slot:modal-footer>
				<div class="">
					<b-button size="sm" style="color: #999797" variant="light" @click="modalEditCloseDate">Cancel</b-button>
				</div>
				<div class="ml-1">
					<b-button size="sm" style="background-color: #51C1AD; color: #FFFFFF" variant="light" @click="handleSubmitDate">Save</b-button>
				</div>
			</template>
		</b-modal>

		<!-- add user -->
		<b-modal id="modal-add-user" ok-title="Save" size="80">
			<template v-slot:modal-title><span class="label-modal">Add User Access</span></template>
			<label label-for="input-2">Username <span class="text-danger" aria-required="true"> * </span></label>
			<b-form-group id="input-group-2">
                    <b-form-input
                    id="input-2"
                    v-model="usersTableAdd.username"
                    placeholder="Username"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3" label-for="input-3">
                    <label label-for="input-1">Email <span class="text-danger" aria-required="true"> * </span></label>
                    <b-form-input
                        id="input-3"
                        v-model="usersTableAdd.email"
                        placeholder="email"
                        required
                        >
                    </b-form-input>
                </b-form-group>
			<template v-slot:modal-footer>
				<div class="">
					<b-button size="sm" style="color: #999797" variant="light" @click="modalEditCloseAddUser">Cancel</b-button>
				</div>
				<div class="ml-1">
					<b-button size="sm" style="background-color: #51C1AD; color: #FFFFFF" variant="light" @click="handleSubmitUser">Save</b-button>
				</div>
			</template>
		</b-modal>

		<!-- edit user -->
		<b-modal id="modal-edit-detail" ok-title="Save" size="80">
			<template v-slot:modal-title><span class="label-modal">Edit User Access</span></template>
			<label label-for="input-2">Username <span class="text-danger" aria-required="true"> * </span></label>
			<b-form-group id="input-group-2">
                    <b-form-input
                    id="input-2"
                    v-model="dataDetail.username"
                    placeholder="Username"
                    required
                    ></b-form-input>
                </b-form-group>

                <b-form-group id="input-group-3" label-for="input-3">
                    <label label-for="input-1">Email<span class="text-danger" aria-required="true"> * </span></label>
                    <b-form-input
                        id="input-3"
                        v-model="dataDetail.email"
                        placeholder="email"
                        required
                        >
                    </b-form-input>
                </b-form-group>
			<template v-slot:modal-footer>
				<div class="">
					<b-button size="sm" style="color: #999797" variant="light" @click="modalEditCloseDetail">Cancel</b-button>
				</div>
				<div class="ml-1">
					<b-button size="sm" style="background-color: #51C1AD; color: #FFFFFF" variant="light" @click="handleSubmitDetail">Save</b-button>
				</div>
			</template>
		</b-modal>
		
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VuetableFieldCheckboxMixin from 'vuetable-2/src/components/VuetableFieldCheckboxMixin.vue'
import { authMethods } from '@state/helpers'
import { mapActions, mapState } from 'vuex'
import moment from 'moment'
import DatePicker from 'vue2-datepicker';
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { integer } from 'vee-validate/dist/rules'


var url = '/shipment-access'

for (
  let i = 0;
  i < JSON.parse(localStorage.getItem('userPermissions')).length;
  i++
) {
  // const element = array[i];
  // if (! JSON.parse(localStorage.getItem('userPermissions'))
  //       .includes(binding.expression
  //         .replace(/'/g, "")
  //           .replace(/"/g, ""))) {
  //      url = '/quotation';
  // }else{
  //   url = '/quotation?admin';
  // }
  // console.log(JSON.parse(localStorage.getItem('userPermissions'))[i])
  if (
    JSON.parse(localStorage.getItem('userPermissions'))[i] ===
    'CRM_ADMIN_ENABLE'
  ) {
    url = '/shipment-access'
  }
}
console.log(url, 'url')
let DB_DATA = [];

export default {
  mixins: [VuetableFieldCheckboxMixin],
  components: {
    Vuetable,
    VuetablePagination,
    VuetablePaginationInfo,
    DatePicker,
	
    // VueGoodTable,
  },
  props: {
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
  		return {
  			moreParams: {},
  			filterText: '',
  			deleteData: {
				id: 0,
				type: ''
			},
			activeExpired: false,
  			isLoadingTable: false,
			show: false,
			showAdd: false,
			usersTable: [],
			usersAccessTable: [],
			expiredData: {
				id: 0,
                expired_date:"",
			},
			dataDetail: {
				username:'',
				email:'',
				id: 0
			},

			usersTableAdd: {
				username: '',
				email:'',
				no_counter: '',
				shipment_access_id: 0,
			},

  			httpOptions: {
  				headers: {
  					Authorization: 'Bearer ' + localStorage.getItem('access_token'),
  				},
  			},
			headers: {
				'Content-Type': 'application/json',
			},
            dataAdd: {
                customer: integer,
                username: "",
                email: "",
                date: "",
            },
			buttonSubmitDisable: false,
  			// page index
  			pageIndex: 1,
  			// page size
  			pageSize: 10,
  			// selected row keys collection
  			selectedRowKeysCollection: [],
  			apiUrl: this.$store.state.rootApi + url,
  			// apiUrlItem: this.$store.state.rootApi + url + '/getItemDummy',
  			urlPdf: '#',
  			items: [{
  					text: 'Master',
  					href: '/',
  				},
  				{
  					text: 'ShipmentAccess',
  					href: '/ShipmentAccess',
  				},
  				{
  					text: 'List',
  					active: true,
  				},
  			],
  			lengthMenu: [5, 10, 25, 50, 100],
  			perPage: 5,
  			fields: [{
  					name: 'no_counter',
  					title: 'No',
  					//   sortField: 'no',
  				},{
  					name: 'customer',
  					title: 'Customer',
  					//   sortField: 'no',
  				},
				{
  					name: 'total_access',
  					title: 'Total Access',
  					// sortField: 'po_number',
					titleClass: 'ml-5',
  					dataClass: 'text-center',
  				},
  				{
  					name: 'expired_date',
  					title: 'Expired Date',
  				},
  				{
  					name: 'created_by',
  					title: 'Created By',
  				},
  				{
  					name: 'is_active',
  					title: 'Active',
  				},
  				{
  					name: 'actions',
  					title: 'Actions',
  					titleClass: 'text-center',
  					// dataClass: 'text-center ',
  				},
  			],
  			fieldsUsers: [{
  					key: 'no_counter',
  					label: 'No',
  					//   sortField: 'no',
  				},{
  					key: 'username',
  					label: 'Username',
  					//   sortField: 'no',
  				},
				{
  					key: 'email',
  					label: 'Email Access',
  					// sortField: 'po_number',
  				},
  				{
  					key: 'actions',
  					label: 'Actions',
  					// dataClass: 'text-center ',
  				},
  			],
  			fieldsAccess: [{
  					key: 'no_counter',
  					label: 'No',
  					//   sortField: 'no',
  				},{
  					key: 'username',
  					label: 'Username',
  					//   sortField: 'no',
  				},
				{
  					key: 'email',
  					label: 'Email Access',
  					// sortField: 'po_number',
  				},
  			],

  			css: {
  				pagination: {
  					activeClass: 'btn-primary text-white',
  					pageClass: 'btn btn-sm ',
  					linkClass: 'btn btn-sm',
  					icons: {
  						prev: 'fas fa-arrow-left',
  						next: 'fas fa-arrow-right',
  					},
  				},
  			},
  			momentFormatDate: {
  				// this.dataPayload.year
  				stringify: (date) => {
					return date ? moment(this.dataAdd.date).format('YYYY-MM-DD') : ''
  				},
  			},
  			momentFormatDateEdit: {
  				// this.dataPayload.year
  				stringify: (date) => {
  					return date ? moment(this.expiredData.expired_date).format('YYYY-MM-DD') : ''
  				},
  			},
  			dateFormating(date) {
				  return date ? moment(date).format('DD MMM YYYY') : ''
  			},
  		}
  	},
  	computed: {
  		...mapState('shipmentAccess', ['listShipmentAccess','listShipmentAccessUsers' ,'listShipmentAccessUserDetail']),
  		...mapState('customer', ['listCustomers','dataCustomer']),
  		currentPageData() {
  			const {
  				pageIndex,
  				pageSize
  			} = this;
  			return DB_DATA.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
  		},
  		// total count
  		totalCount() {
  			return DB_DATA.length;
  		},
  	},
  	mounted() {
        this.getListCustomers()
  	},

  	methods: {
  		...authMethods,
  		loginByToken() {
  			console.log('login by token')
  		},
  		...mapActions('customer', ['getListCustomers','getCustomer']),
  		...mapActions('shipmentAccess', ['saveShipmentAccess', 'saveUsers', 'changeStatus','getDataAccessUsers' ,'getDataAccessUserDetail', 'saveEditDate', 'saveEditDetail','deletesData']),
		
        openModalAdd() {
            this.$bvModal.show('modal-add')
        },

		openModalEdit(id){
			this.getDataAccessUsers(id)
				.then((res) => {
					
					this.usersTable = []
					this.expiredData.expired_date = this.listShipmentAccessUsers.shipment.expired_date
					this.expiredData.id = this.listShipmentAccessUsers.shipment.id
					console.log(this.expiredData,'expiredData');
					if(this.listShipmentAccessUsers.user.length > 0){
						this.listShipmentAccessUsers.user.forEach((el) => {
							this.usersTable.push(el)
						})
					}
					this.$bvModal.show('modal-edit')
				})
		},
		openModalAccess(id){
			this.getDataAccessUsers(id)
				.then((res) => {
					
					this.usersAccessTable = []
					this.expiredData.expired_date = this.listShipmentAccessUsers.shipment.expired_date
					this.expiredData.id = this.listShipmentAccessUsers.shipment.id
					console.log(this.expiredData,'expiredData');
					if(this.listShipmentAccessUsers.user.length > 0){
						this.listShipmentAccessUsers.user.forEach((el) => {
							this.usersAccessTable.push(el)
						})
						console.log(this.usersAccessTable,'usertable');
					}
					this.$bvModal.show('modal-total-access')
				})
		},
			
		openModalEditDate(){
			this.$bvModal.show('modal-edit-date')
		},
		openModalAddUser(){
			this.$bvModal.show('modal-add-user')
		},
		openModalEditDetail(id){
			this.getDataAccessUserDetail(id)
				.then((res) => {
					this.dataDetail.username = this.listShipmentAccessUserDetail.username
					this.dataDetail.email = this.listShipmentAccessUserDetail.email
					this.dataDetail.id = this.listShipmentAccessUserDetail.id
					this.$bvModal.show('modal-edit-detail')
				})
		},

		dateFormate() {
			return moment(this.dataAdd.date).format('YYYY MMM DD')
  		},

		modalEditClose(){
			this.$bvModal.hide('modal-edit')
		},
		modalAccessClose(){
			this.$bvModal.hide('modal-total-access')
		},
		modalEditCloseDate(){
			this.$bvModal.hide('modal-edit-date')
		},
		modalEditCloseAddUser(){
			this.usersTableAdd = {
				username: '',
				email:'',
				no_counter: '',
				shipment_access_id: 0,
			}
			this.$bvModal.hide('modal-add-user')
		},
		modalEditCloseDetail(){
			this.$bvModal.hide('modal-edit-detail')
		},

		async saveAdd(bvModalEvent){
			bvModalEvent.preventDefault()
			this.buttonSubmitDisable = true
			await this.validateForm()
				.then(() => {
					this.handleSubmit()
				})
				.catch(() => {
					this.buttonSubmitDisable = false
					
				})
		},

		handleSubmit(){
			// console.log(this.dataAdd,'dataadd');
			this.saveShipmentAccess({
				payload: this.dataAdd,
				headers: this.headers
			})
			.then((res) => {
				if (res.status) {
					Swal.fire({
					title: "Success!",
					text: res.message,
					icon: 'success',
					confirmButtonColor: '#51C1AD',
					confirmButtonText: 'Ok'
					}).then((res) => {
							this.$nextTick(() => {
								this.buttonSubmitDisable = false
								this.$bvModal.hide('modal-add')
								this.$nextTick(() => this.$refs.vuetable.refresh())
							})

					})

				} else {
					this.buttonSubmitDisable = false
					Swal.fire({
						title: "Failed!",
						text: res.message,
						icon: 'warning',
						confirmButtonColor: 'rgb(221, 107, 85)',
						confirmButtonText: 'Ok'
					})
				}
			})
			.catch((err) => {
				this.buttonSubmitDisable = false
				Swal.fire({
					title: "Failed!",
					text: err.response.message,
					icon: 'warning',
					confirmButtonColor: 'rgb(221, 107, 85)',
					confirmButtonText: 'Ok'
				})
			})
		},
		handleSubmitEditUsers(){
			// console.log(this.usersTable,'usertables');
			this.saveUsers({
				payload: this.usersTable,
				headers: this.headers
			})
			.then((res) => {
				this.sweetAlerts(res, ['modal-edit'])
			})
			.catch((err) => {
				this.buttonSubmitDisable = false
				Swal.fire({
					title: "Failed!",
					text: err.response.message,
					icon: 'warning',
					confirmButtonColor: 'rgb(221, 107, 85)',
					confirmButtonText: 'Ok'
				})
			})
		},
		handleSubmitDate(){
			this.saveEditDate({
				payload: this.expiredData,
				headers: this.headers
			})
			.then((res) => {
				this.sweetAlerts(res, ['modal-edit', 'modal-edit-date'])
			})
		},
		handleSubmitUser(){
			this.usersTableAdd.no_counter = this.usersTable.length + 1
			this.usersTableAdd.shipment_access_id = this.expiredData.id
			this.usersTable.push(this.usersTableAdd)
			// this.$nextTick(() => 
			// 	this.$refs.tableEdit.refresh()
			// )
			this.modalEditCloseAddUser()
		},
		handleSubmitDetail(){
			this.saveEditDetail({
				payload: this.dataDetail,
				headers: this.headers
			})
			.then((res) => {
				this.sweetAlerts(res, ['modal-edit', 'modal-edit-detail'])
			})
		},

		sweetAlerts(res, modal){
			if (res.status) {
				Swal.fire({
				title: "Success!",
				text: res.message,
				icon: 'success',
				confirmButtonColor: '#51C1AD',
				confirmButtonText: 'Ok'
				}).then((res) => {
						this.$nextTick(() => {
							this.buttonSubmitDisable = false
							modal.forEach(element => {
								this.$bvModal.hide(element)
							});
							this.$nextTick(() => 
							this.$refs.vuetable.refresh()
							)
							this.$nextTick(() => 
							this.$refs.tableEdit.refresh()
							)
						})

				})

			} else {
				Swal.fire({
					title: "Failed!",
					text: res.message,
					icon: 'warning',
					confirmButtonColor: 'rgb(221, 107, 85)',
					confirmButtonText: 'Ok'
				})
			}
		},

		isExpired(date){
			let varDate = new Date(date);
			let today = new Date();

			if(today > varDate) {
				return true;
			}
		},

		validateForm(){
  			// Ini pake Promise biar ngga asynchronus, karna klo ga pake promise jadinyaa error
  			return new Promise((resolve, reject) => {
  				if (this.dataAdd.username === "" && this.dataAdd.username === "") {
  					Swal.fire({
  						title: "Failed!",
  						text: "Please insert field Username",
  						icon: 'warning',
  						confirmButtonColor: 'rgb(221, 107, 85)',
  						confirmButtonText: 'Ok'
  					})

  					reject(console.log('required'))
  				}
  				if (this.dataAdd.email === "" && this.dataAdd.email === "") {
  					Swal.fire({
  						title: "Failed!",
  						text: "Please insert field Email",
  						icon: 'warning',
  						confirmButtonColor: 'rgb(221, 107, 85)',
  						confirmButtonText: 'Ok'
  					})

  					reject(console.log('required'))
  				}
  				if (this.dataAdd.date === "" && this.dataAdd.date === "") {
  					Swal.fire({
  						title: "Failed!",
  						text: "Please insert field Date",
  						icon: 'warning',
  						confirmButtonColor: 'rgb(221, 107, 85)',
  						confirmButtonText: 'Ok'
  					})

  					reject(console.log('required'))
  				}
				
  				
  				resolve(true)
  			})
  		},

		resetModalAdd() {
			this.showAdd = false;
			this.dataAdd.customer = '';
			this.dataAdd.email = '';
			this.dataAdd.date = '';
			this.dataAdd.username = '';
		},
        
        changeCustomer(){
			this.getCustomer(this.dataAdd.customer)
				.then(() => {
					this.dataAdd.email = this.dataCustomer.email
					this.dataAdd.customer ? this.showAdd = true : this.showAdd = false
				})
        },

		changeActive(id) {
			this.changeStatus(id)
				.then((res) => {
					if (res.status) {
					Swal.fire({
					title: "Success!",
					text: res.message,
					icon: 'success',
					confirmButtonColor: '#51C1AD',
					confirmButtonText: 'Ok'
					}).then((res) => {
							this.$nextTick(() => {
								this.$nextTick(() => this.$refs.vuetable.refresh())
							})

					}) 
					} else {
						this.buttonSubmitDisable = false
						Swal.fire({
							title: "Failed!",
							text: res.message,
							icon: 'warning',
							confirmButtonColor: 'rgb(221, 107, 85)',
							confirmButtonText: 'Ok'
						})
					}
				})
		},

        doFilter() {
  			this.onFilterSet(this.filterText)
  		},
  		/**
  		 * Table Length Change
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		onChangeTableLength() {
  			// this.$refs.vuetable.reload()
  			this.$nextTick(() => this.$refs.vuetable.refresh())
  		},
  		onChangeTableLengthItem() {
  			// this.$refs.vuetable.reload()
  			this.$nextTick(() => this.$refs.vuetable.refresh())
  		},
  		/**
  		 * Pagination page change
  		 */
  		onChangePage(page) {
  			this.$refs.vuetable.changePage(page)
  		},
  		onChangePageItem(page) {
  			this.$refs.vuetableItem.changePage(page)
  		},
  		/**
  		 * Pagination info and pagination show
  		 */
  		onPaginationData(paginationData) {
  			this.$refs.pagination.setPaginationData(paginationData)
  			this.$refs.paginationInfo.setPaginationData(paginationData)
  		},
  		onPaginationDataItem(paginationData) {
			console.log(this.$refs,'refsspagg');
  			this.$refs.paginationItem.setPaginationData(paginationData)
  			// this.$refs.paginationInfo.setPaginationData(paginationData)
			// this.$nextTick(() => this.$refs.vuetable.refresh())
  		},

		
  		/**
  		 * [onFilterSet description]
  		 *
  		 * @param   {[type]}  filterText  [filterText description]
  		 *
  		 * @return  {[type]}              [return description]
  		 */
  		onFilterSet(filterText) {
  			this.moreParams = {
  				search: filterText,
  			}
  			this.$nextTick(() => this.$refs.vuetable.refresh())
  		},
  		/**
  		 * Show Table Loading
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		loadingTable() {
  			this.isLoadingTable = true
  		},

  		/**
  		 * Hide Loading if Success
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		loadTableSuccess() {
  			this.isLoadingTable = false
  		},
  		/**
  		 * Hide Loading if Failed
  		 *
  		 * @return  {[type]}  [return description]
  		 */
  		loadTableFailed(error) {
  			console.log(error)

  			this.isLoadingTable = false
  			this.$swal({
  				title: 'Terjadi Kesalahan',
  				type: 'error',
  				timer: '2000',
  				toast: true,
  				position: 'bottom-end',
  			})
  			// }
  		},
  		
  		showConfirmDelete(id, type) {
  			this.deleteData.id = id
  			this.deleteData.type = type


  			Swal.fire({
  				title: 'Are you sure?',
  				text: 'You will not be able to recover this data!',
  				icon: 'warning',
  				showCancelButton: true,
  				confirmButtonColor: 'rgb(221, 107, 85)',
  				cancelButtonColor: '#C1C1C1',
  				reverseButtons: true,
  				confirmButtonText: 'Yes, delete it!',
  			}).then((result) => {
  				if (result.isConfirmed) {
  					this.onDelete()
  					// Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
  				}
  			})
  		},
  		onDelete() {
  			this.deletesData({
				payload: this.deleteData,
				headers: this.headers
			})
  				.then((res) => {
					this.sweetAlerts(res, ['modal-edit', 'modal-edit-detail'])	
  				})
  				.catch(() => {
  						Swal.fire({
  							title: "Failed!",
  							text: 'failed',
  							icon: 'warning',
  							confirmButtonColor: 'rgb(221, 107, 85)',
  							confirmButtonText: 'Ok'
  						})
  					})
  		},
		getDate(){
  			this.dataAdd.date = moment(this.dataAdd.date).format('YYYY-MM-DD')
		}
  	},
  }
</script>
<style>
  .modal-xl {
    max-width: 95% !important;
  }
  .btn-cancel {
      /* width: 90px; */
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      height: 34px;
      background-color: #E0E0E0;
      color: #817F7F;
      border: 0;
      border-radius: 3px;
  }

  .btn-next {
      /* width: 90px; */
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      height: 34px;
      background-color: #51C1AD !important;
      color: #fff;
      border: 0;
      border-radius: 3px;
  }

  .btn-finish {
      /* width: 120px; */
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      height: 34px;
      background-color: #51C1AD;
      color: #fff;
      border: 0;
      border-radius: 3px;
  }

  .add-loading {
      background-color: #FFF2D9;
      border-radius: 5px;
      padding: 1em;
  }

  .add-unloading {
      background-color: #FFF2D9;
      border-radius: 5px;
      padding: 1em;
  }

  .btn-new-transaction {
      background-color: #F9A707;
      margin-right: -3.5rem;
  }

  .btn-new-transaction:hover {
      color: white;
  }

  /* .wizard-icon-circle.checked {
    color: rgb(249, 167, 7);
  } */

  .style-chooser .vs__search::placeholder,
  .style-chooser .vs__dropdown-toggle,
  .style-chooser .vs__dropdown-menu {
      border-color: #f1556c;
  }

  .__style-dete-required {
      border: 1px solid #f1556c;
      border-radius: 4px;
  }

  #modalReceivableItem___BV_modal_footer_ {
    display: none;
  }

  .btn-action {
    width: 25px;
    height: 25px;
    padding: 0;
  }

  #modalNumberReff___BV_modal_footer_ {
    display: none;
  }
  .table-button-container {
    min-width: 150px;
  }

  .pagination .btn {
    color: #000 !important;
  }

  .pagination .btn.text-white {
    color: #FFF !important;
  }

  .new-card .card-body {
    padding-left: 10px;
    padding-right: 10px;
  }
  .thead{
      background-color:white !important; 
      color:#a2a2a2 !important;
  }
  .tableItem .vuetable-body-wrapper .vuetable thead tr th{
    background-color: white;
    border:0px white solid
  }
  .tableItem .vuetable-body-wrapper .vuetable .vuetable-body tr{
    /* background-color: white; */
    border-width: 0;
    /* border:0px white solid */
  }
  .button-ri .btn-btn-drop {
	background-color: #cffdf5;
	border-color: #cffdf5;
}

.button-ri .show>.btn-btn-drop.dropdown-toggle {
	background-color: #cffdf5;
	border-color: #cffdf5;
}

.button-ri .btn-btn-drop:hover {
	background-color: #cffdf5;
	border-color: #cffdf5;
}

.button-ri .btn-btn-drop:not(:disabled):not(.disabled):active,
.btn-btn-drop:not(:disabled):not(.disabled).active,
.show>.btn-btn-drop.dropdown-toggle {
	background-color: #cffdf5;
	border-color: #cffdf5;
}
.headerTooltip {
	background-color: #cffdf5;
}
.centerPo{
	margin-left:20px
}

.form-control:disabled, .form-control[readonly] {
	background-color: var(--vs-disabled-bg);
	opacity: 1;
}

.labelTable{
	font-size: 8px;
}

.desc-email-add{
    font-size: 10px;
    font-weight: 400;
    font-style: italic;
    color: #51C1AD;
}

.label-modal{
    font-weight: 700;
    font-size: 15px
}

.table-total-access{
	background-color: #f4f4f4;
}
#modal-edit___BV_modal_footer_{
	display: flex;
	justify-content: start !important;
	width: 100% !important;
}

/* responsive design */
@media only screen and (max-width: 500px) {
  .limitForm {
    position: relative;
	top: 8px
  }
  .body {
    background-color: red;
  }
}

</style>